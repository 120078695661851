<template>
    <page title="请假详情" :hasBack="true" textAlign="left" :backHome="backHome" >
        <div class="leave-detail-page">
            <common-block class="leave-msg" title="请假信息">
                <leave-des :leave-msg="leaveMsg" />
                <triangle :type="leaveMsg.status" />
            </common-block>
            <common-block class="process" title="审批流程">
                <common-step :stepList="stepList" :styleApprove="true" />
            </common-block>
            <common-block class="reason" :border="false" title="审批意见">
                <van-field v-if="leaveMsg.status !== 0" v-model="leaveMsg.verifyDesc" rows="4" :disabled="true"  autosize type="textarea"  />
                <van-field v-else v-model="reason" rows="4"  autosize type="textarea" placeholder="请点击输入审批意见..." />
            </common-block>
            <div class="btn-wrapper" :class="{ center: leaveMsg.status !== 0 }">
                <van-button v-if="leaveMsg.status === 0" class="confirm-btn" round type="warning" @click="changeApprove(0)">狠心驳回</van-button>
                <van-button v-if="leaveMsg.status === 0" class="confirm-btn" round type="primary" @click="changeApprove(1)">确定同意</van-button>
                <van-button v-if="leaveMsg.status === 1" class="confirm-btn" round type="primary" @click="cancelLeave">销假</van-button>
            </div>
        </div>
    </page>
</template>
<script>
import Page from '@/components/page/Page';
import CommonBlock from '@/components/commonBlock/CommonBlock';
import LeaveDes from '@/components/leaveDes/LeaveDes';
import Triangle from '@/components/triangle/Triangle';
import CommonStep from '@/components/commonStep/CommonStep';
import {Button, Field, Toast} from 'vant';
import { reactive, ref } from 'vue';
import {useRoute, useRouter} from "vue-router";
import commonRequest from '@/services';
export default {
    name: 'LeaveDetail',
    components: {
        [Page.name]: Page,
        [CommonBlock.name]: CommonBlock,
        [LeaveDes.name]: LeaveDes,
        [Triangle.name]: Triangle,
        [CommonStep.name]: CommonStep,
        [Button.name]: Button,
        [Field.name]: Field,
    },
    setup() {
        const route = useRoute();
        const router = useRouter();
        const leaveMsg = ref({
        });
        const reason = ref('');
        const stepList = reactive([
        ]);
        commonRequest({ action: 11006, id: route.query.id }).then((res) => {
            leaveMsg.value = res.data;
            stepList[0] = {title: '审批人', content: res.data.teacherName + '老师'};
            stepList[1] = {title: '审批意见'};
            if (res.data.status === 0) {
                stepList[1].content = '待批假';
                stepList[1].color= '#EE8A32';
            } else if (res.data.status === 1) {
                stepList[1].content = '已批假';
                stepList[1].color= '#1BD188';
            } else if (res.data.status === 4) {
                stepList[1].content = '已完成';
                stepList[1].color= '#E64141';
            } else if (res.data.status === 3) {
                stepList[1].content = '已撤销';
                stepList[1].color= '#999999';
            } else if (res.data.status === 2) {
                stepList[1].content = '已驳回';
                stepList[1].color= '#F7F9FA';
            }
            if (res.data.verifyDesc) {
                stepList[2] = {title: '审批意见', content: res.data.verifyDesc};
            }
            if (res.data.verifyTime) {
                stepList[3] = {title: '审批时间', content: res.data.verifyTime};
            }
        });

        const changeApprove = (status) => {
            commonRequest({ action: 12003, id: route.query.id, status, verifyDesc: reason.value }).then(() => {
                Toast.success('审批成功');
                setTimeout(() => {
                    if (route.query.backHome) {
                        router.replace('/teacherHome')
                    } else {
                        router.back();
                    }
                }, 100);
            })
        };
        const cancelLeave = () => {
            router.push({
                path: '/cancelLeave',
                query: {id: route.query.id}
            })
        };

        return {
            leaveMsg,
            stepList,
            reason,
            changeApprove,
            cancelLeave,
            backHome: route.query.backHome
        };
    },
};
</script>
<style lang="less" scoped>
.leave-detail-page {
    .process {
        /deep/ .common-inner {
            border: none;
            margin-top: -12.5px;
        }
    }
    .btn-wrapper {
        display: flex;
        justify-content: space-between;
        padding: 0 23px;
        box-sizing: border-box;
        margin-top: 35px;
        margin-bottom: 20px;
        &.center {
            justify-content: center;
        }
        /deep/ .van-button.van-button--primary {
            padding: 0 29px;
        }
        /deep/ .van-button.van-button--warning {
            padding: 0 29px;
        }
    }
}
</style>
